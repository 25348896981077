import React from 'react'
import Wistia from './Wistia'

export default class CommunityDemo extends React.Component {
  render() {
    return (
      <div className="communityDemo">
        <div className="communityHeader">
          <div className="logo">
            <img src="/img/rc_logo.png" />
          </div>
          <div className="searchBar">
            <img src="/img/search.svg" />
          </div>
          <ul className="icons">
            <li><img src="/img/stats.svg" /></li>
            <li><img src="/img/messages.svg"  /></li>
            <li><img src="/img/notifications.svg" /></li>
            <li><img src="/img/avatars/avatar-3.jpg" className="avatar" /></li>
          </ul>
        </div>
        <div className="communityBody">
          <div className="leftSidebar">
            <h4>Reno Collective</h4>
            <ul>
              <li>🎉 Start Here</li>
              <li>👋 Introduce Yourself</li>
              <li>👨🏼‍🚀 Our Members</li>
              <li>📢 Announcements</li>
            </ul>

            <h4>Community</h4>
            <ul>
              <li>📆 Event Calendar</li>
              <li>☕ Cowork with Us</li>
              <li>🏆‍ Weekly Wins</li>
              <li>🙋‍♀️ Ask a Question</li>
              <li>👀 Ask for Feedback</li>
              <li class="active">🚀 Courses</li>
              <li>⚛️ Jobs & Gigs</li>
              <li>🧘‍♀️ Health & Wellness</li>
              <li>📖 Book Club</li>
              <li>💰 Member Perks</li>
            </ul>
          </div>
          <div className="middle">
            <h3>Live Session 42 - How to figure out your rate as a Freelancer</h3>
            <div className="bio">
              <img src="/img/avatars/rachelk.jpg" className="avatar" />
              <div>
                <div className="username">Rachel Kingham</div>
                <div className="byline"></div>
              </div>
            </div>
            <div className="liveVideo">
              <div class="liveNow"><div class="redDot"></div><div>LIVE</div></div>
              <Wistia />
            </div>
          </div>
          <div className="rightSidebar">
            <div className="panel">
              <h4>Members</h4>
              <div className="bio">
                <img src="/img/avatars/rachelk.jpg" className="avatar" />
                <div>
                  <div className="username">Rachel K</div>
                  <div className="byline"></div>
                </div>
              </div>
              <div className="bio">
                <img src="/img/avatars/colin.jpg" className="avatar" />
                <div>
                  <div className="username">Colin L</div>
                  <div className="byline"></div>
                </div>
              </div>
              <div className="bio">
                <img src="/img/avatars/sue.jpg" className="avatar" />
                <div>
                  <div className="username">Sue-Ting C</div>
                  <div className="byline"></div>
                </div>
              </div>
              <div className="bio">
                <img src="/img/avatars/don.jpg" className="avatar" />
                <div>
                  <div className="username">Don M</div>
                  <div className="byline"></div>
                </div>
              </div>
            </div>
            <div className="panel">
              <h4>Trending Topics</h4>
              <div class="faux-topic"></div>
              <div class="faux-topic"></div>
              <div class="faux-topic"></div>
            </div>
            <div className="panel">
              <h4>Pinned Topics</h4>
              <div class="faux-topic"></div>
              <div class="faux-topic"></div>
              <div class="faux-topic"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
