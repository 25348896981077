import React from 'react'

export default class Wistia extends React.Component {

  render() {
    return (
      <div>
        <div class="wistia_responsive_padding" style={{padding: 66.67 + "% 0 0 0", position: "relative"}}><div class="wistia_responsive_wrapper" style={{height: 100 + "%", left: 0, position: "absolute", top: 0, width: 100 + "%"}}><div class="wistia_embed wistia_async_h3mq3pkstz seo=false videoFoam=true" style={{height:100 + "%", position: "relative", width: 100 + "%", borderRadius: 5 + "px" }}>&nbsp;</div></div></div>
      </div>
    );
  };
}
